<template>
    <psi-dialog
        :title="title"
        :icon="icon"
        :heading-color="`${color} darken-1`"
        hide-actions
        @close="$emit('close')"
    >
        <v-row>
            <v-col lg="5" md="6" sm="12">
                <contact-view
                    :color="color"
                    :label="contactData.label"
                    :type="contactData.type"
                    :contact="contactData.primary_contact"
                ></contact-view>
                <v-divider></v-divider>
                <template v-for="(contact, index) in contacts">
                    <contact-view
                        :key="index"
                        :contact="contact"
                        :color="color"
                    ></contact-view>
                </template>
            </v-col>

            <v-col lg="7" md="6" sm="12">
                <div class="d-flex mb-0">
                    <v-spacer></v-spacer>
                    <v-switch
                        class="mb-0 mt-1"
                        label="Advanced Search"
                        v-model="advanced"
                        :color="`${color} darken-1`"
                    ></v-switch>
                </div>
                <v-divider class="mb-2"></v-divider>

                <!-- Advanced search includes form, results -->
                <lookup-advanced-search
                    v-if="advanced"
                    v-model="contacts"
                    :entries="entries"
                    :color="color"
                    :contact-data="contactData"
                ></lookup-advanced-search>
                <!-- Simple search , single type ahead drop down -->
                <lookup-simple-search
                    :entries="entries"
                    v-else
                ></lookup-simple-search>
            </v-col>
        </v-row>
    </psi-dialog>
</template>
<script>
import api from "@components/screen/api";

export default {
    name: "employer-landlord-lookup",
    components: {
        ContactView: () => import("../Contact/ContactView.vue"),
        LookupSimpleSearch: () =>
            import("./EmployerLandlordLookupSimpleSearch"),
        LookupAdvancedSearch: () =>
            import("./EmployerLandlordLookupAdvancedSearch"),
    },
    props: {
        contactData: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "landlord",
            validator: (value) => ["landlord", "employer"].includes(value),
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Landlord Lookup",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-home-search",
        },
    },
    data() {
        return {
            advanced: false,
            entries: [],
            contacts: [],
        };
    },
    async created() {
        // TODO: Pull these based on the search entry or from an advanced search form
        this.entries =
            this.type === "landlord"
                ? await api.getLandlords()
                : await api.getEmployers();
    },
};
</script>

<style scoped>
</style>