var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: {
        title: _vm.title,
        icon: _vm.icon,
        "heading-color": _vm.color + " darken-1",
        "hide-actions": ""
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "5", md: "6", sm: "12" } },
            [
              _c("contact-view", {
                attrs: {
                  color: _vm.color,
                  label: _vm.contactData.label,
                  type: _vm.contactData.type,
                  contact: _vm.contactData.primary_contact
                }
              }),
              _c("v-divider"),
              _vm._l(_vm.contacts, function(contact, index) {
                return [
                  _c("contact-view", {
                    key: index,
                    attrs: { contact: contact, color: _vm.color }
                  })
                ]
              })
            ],
            2
          ),
          _c(
            "v-col",
            { attrs: { lg: "7", md: "6", sm: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex mb-0" },
                [
                  _c("v-spacer"),
                  _c("v-switch", {
                    staticClass: "mb-0 mt-1",
                    attrs: {
                      label: "Advanced Search",
                      color: _vm.color + " darken-1"
                    },
                    model: {
                      value: _vm.advanced,
                      callback: function($$v) {
                        _vm.advanced = $$v
                      },
                      expression: "advanced"
                    }
                  })
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-2" }),
              _vm.advanced
                ? _c("lookup-advanced-search", {
                    attrs: {
                      entries: _vm.entries,
                      color: _vm.color,
                      "contact-data": _vm.contactData
                    },
                    model: {
                      value: _vm.contacts,
                      callback: function($$v) {
                        _vm.contacts = $$v
                      },
                      expression: "contacts"
                    }
                  })
                : _c("lookup-simple-search", {
                    attrs: { entries: _vm.entries }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }